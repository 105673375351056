.form-desc p:not(:first-child) {
    margin-top: 1.5rem;
}
.form-desc a {
    @apply border-b border-gray-300 dark:border-gray-700 pb-px;
}
.form-desc a:hover {
    @apply border-gray-500 dark:border-gray-600 pb-px;
}
input {
    -webkit-appearance: none;
    border-radius: 0;
}

@media (max-width: 500px) {
    .h-full:not(.resize-none), .h-screen {
        height: 100vh;
        height: -webkit-fill-available;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

@media (prefers-color-scheme: dark) {input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: white;
    }
}